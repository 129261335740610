import { Trans } from 'react-i18next';
import { Box, Grid, IconButton, Link, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { LinkText } from 'components/ui';
import { Instagram } from '@mui/icons-material';

export function Footer() {
  const currentYear = () => {
    return DateTime.now().year;
  };

  return (
    <Box
      sx={{
        borderTop: '1px solid white',
        backgroundColor: 'rgb(38, 43, 43)',
        width: '100vw',
        color: 'white',
        flexShrink: 0,
        fontSize: 'large',
        fontFamily: 'Bebas',
        height: '65px',
        zIndex: '25',
      }}
      id='footer'
    >
      <Grid
        container
        sx={{
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <Typography>{currentYear()}</Typography>
          <Typography sx={{ '@media(max-width:300px)': { display: 'none' } }}>
            |
          </Typography>
          <Typography> {process.env.REACT_APP_VERSION}</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Link target='_blank' href='//instagram.com/thestrallarp'>
            <IconButton sx={{ padding: '10px' }}>
              <Instagram sx={{ color: '#ffffff' }} />
            </IconButton>
          </Link>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '@media(max-width:570px)': {
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'center',
            },
          }}
        >
          <LinkText to={'/imprint'} underline='none' color='#ffffff'>
            <Typography
              sx={{
                fontSize: '1.2rem',
                '@media(max-width:570px)': { fontSize: '1rem' },
              }}
            >
              <Trans i18nKey='Components.Imprint.Title' />
            </Typography>
          </LinkText>
          <Typography sx={{ '@media(max-width:570px)': { display: 'none' } }}>
            |
          </Typography>

          <LinkText to={'/privacy'} underline='none' color='#ffffff'>
            <Typography
              sx={{
                fontSize: '1.2rem',
                '@media(max-width:570px)': { fontSize: '1rem' },
              }}
            >
              <Trans i18nKey='Components.Privacy.Title' />
            </Typography>
          </LinkText>
        </Grid>
      </Grid>
    </Box>
  );
}

import { Box, Typography } from '@mui/material';

import bg2 from '../../../assets/images/bg_landing/bg-2.svg';
import bg3 from '../../../assets/images/bg_landing/bg-3.svg';
import bg4 from '../../../assets/images/bg_landing/bg-4.svg';
import bg5 from '../../../assets/images/bg_landing/bg-5.svg';
import bg6 from '../../../assets/images/bg_landing/bg-6.svg';
import bg7 from '../../../assets/images/bg_landing/bg-7.svg';
import bg1 from '../../../assets/images/bg_landing/bg-1.svg';

import { Trans } from 'react-i18next';
import { ReactNode, useCallback, useEffect, useState } from 'react';

type Props = {
  title: string;
  caption?: string;
};

export function Parallax(props: Readonly<Props>): ReactNode {
  const [scrollY, setScrollY] = useState<number>(0);

  const standardStyle = {
    position: 'absolute',
    bottom: 0,
    width: '100vw',
    height: '100vh',

    '@media(max-width:1920px)': {
      width: '1920px',
    },

    '@media(max-width:600px)': {
      width: '1500px',
    },

    objectfit: 'cover',
  };

  const handleScroll = useCallback(() => setScrollY(window.scrollY), []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <Box
      id='parallax-hero'
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
        marginTop: '-100px',
        overflow: 'hidden',
        backgroundColor: '#151718',
      }}
    >
      <Box
        component='img'
        sx={{
          ...standardStyle,
          marginBottom: '10px',
        }}
        src={bg1}
      />
      <Box
        component='img'
        sx={{
          ...standardStyle,
          transform: `translate(200px, ${scrollY * 0.5}px)`,
          transition: 'transform 0.25s linear',
        }}
        src={bg2}
      />

      <Box
        component='img'
        sx={{
          ...standardStyle,
          transform: `translate(-${scrollY * 0.5}px, ${scrollY * 0.25}px)`,
          transition: 'transform 0.25s linear',
        }}
        src={bg3}
      />
      <Box
        component='img'
        sx={{
          ...standardStyle,
          transform: `translate(${scrollY * 0.5}px, ${scrollY * 0.25}px)`,
          transition: 'transform 0.25s linear',
        }}
        src={bg4}
      />
      <Box
        component='img'
        sx={{
          ...standardStyle,
          transform: `translate(-${scrollY * 1.5}px, 100px)`,
          transition: 'transform 0.25s linear',

          marginLeft: '-5px',
        }}
        src={bg5}
      />

      <Box
        id='hero-text'
        sx={{
          position: 'absolute',
          marginTop: '-100px',
          opacity: `${100 - scrollY * 0.2}%`,
          transform: `translateY(${scrollY * 1.5}px)`,
          transition: 'transform 0.25s linear',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant='h1'
          sx={{
            fontWeight: '700',
            textAlign: 'center',

            '@media(max-width:4000px)': {
              fontSize: '8rem',
            },

            '@media(max-width:1440px)': {
              fontSize: '4rem',
            },

            '@media(max-width:600px)': {
              fontSize: '3.5rem',
            },
            '@media(max-width:350px)': {
              fontSize: '3rem',
            },
          }}
        >
          <Trans i18nKey={props.title} />
        </Typography>
        <Typography
          variant='caption'
          sx={{
            fontSize: '2rem',
            fontWeight: '500',

            '@media(max-width:4000px)': {
              fontSize: '3rem',
            },

            '@media(max-width:1440px)': {
              fontSize: '1.5rem',
            },

            '@media(max-width:600px)': {
              fontSize: '1.5rem',
            },
            '@media(max-width:350px)': {
              fontSize: '1rem',
            },
          }}
        >
          <Trans i18nKey={props.caption} />
        </Typography>
      </Box>
      <Box
        component='img'
        sx={{
          ...standardStyle,
          transform: `translatex(${scrollY * 1.5}px)`,
          transition: 'transform 0.25s linear',
        }}
        src={bg6}
      />
      <Box
        component='img'
        sx={{
          ...standardStyle,
          transform: 'translatey(20px)',
        }}
        src={bg7}
      />
    </Box>
  );
}

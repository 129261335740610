import { Box } from '@mui/material';
import { motion } from 'framer-motion';

type Props = {
  id?: string;
  children: React.ReactNode;
};
const FadeInBox = motion(Box);

export function MotionBox({ id, children }: Readonly<Props>) {
  return (
    <FadeInBox
      id={id}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 1, ease: 'easeOut', delay: 0.2 }}
    >
      {children}
    </FadeInBox>
  );
}

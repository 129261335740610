import { ReactNode } from 'react';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

type Props = {
  value?: string;
  label?: string;
  readonly?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  error?: boolean;
  maxDate?: string;
  minDate?: string;
  helperText?: string;
  onChange?: (event) => void;
};

export function DateField(props: Readonly<Props>): ReactNode {
  const toDateTime = (isoDate) => {
    return DateTime.fromISO(isoDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='de'>
      <DatePicker
        label={props.label}
        value={props.value && toDateTime(props.value)}
        maxDate={toDateTime(props.maxDate)}
        minDate={toDateTime(props.minDate)}
        onChange={props.onChange}
        displayWeekNumber
        disableHighlightToday
        disableFuture={props.disableFuture}
        disablePast={props.disablePast}
        views={['year', 'month', 'day']}
        slotProps={{
          day: {
            sx: {
              color: '#fff',
              '.MuiPickersDay-today': {
                borderColor: '#f00',
              },
            },
          },
          clearButton: {
            sx: {
              color: 'primary.main',
            },
          },
          openPickerButton: {
            sx: {
              color: 'primary.main',
            },
          },
          layout: {
            sx: {
              backgroundColor: '#1b1b1b',
              borderColor: '#ff0000',
              color: 'primary',
              '.MuiDayCalendar-weekDayLabel': {
                color: 'primary.main',
              },
              '.MuiDayCalendar-weekNumber': {
                color: 'primary.main',
              },
              '.MuiDayCalendar-weekNumberLabel': {
                color: 'primary.main',
              },
              '.MuiPickersCalendarHeader-switchViewButton': {
                color: '#ffffff',
              },
              '.MuiPickersArrowSwitcher-button': {
                color: 'primary.main',
              },
              '.Mui-disabled': {
                color: '#acacac',
              },
            },
          },
          textField: {
            error: props.error,
            helperText: props.helperText,
            color: 'primary',
            variant: 'standard',

            InputLabelProps: {
              sx: {
                fontSize: '1.1rem',
                fontFamily: 'Neuton',
                color: 'rgba(255, 255, 255, 0.7)',
                '& .MuiInputBase-input.Mui-disabled': {
                  color: 'rgba(206, 205, 205, 0.7)',
                  WebkitTextFillColor: 'rgba(206, 205, 205, 0.7)',
                },
                '@media(max-width:5000px)': {
                  fontSize: '1.2rem',
                  lineHeight: '1.5rem',
                },

                '@media(max-width:1440px)': {
                  fontSize: '1.0rem',
                  lineHeight: '1.3rem',
                },

                '@media(max-width:800px)': {
                  fontSize: '0.8rem',
                  lineHeight: '1rem',
                },
              },
            },
            InputProps: {
              readOnly: props.readonly,
              sx: {
                color: '#fff',
                fontFamily: 'Neuton',
                fontSize: '16px',
                marginBottom: '10px',
                '& .MuiInputBase-input.Mui-disabled': {
                  color: 'rgba(206, 205, 205, 0.7)',
                  WebkitTextFillColor: 'rgba(206, 205, 205, 0.7)',
                },
                '@media(max-width:5000px)': {
                  fontSize: '1.2rem',
                  lineHeight: '1.5rem',
                },

                '@media(max-width:1440px)': {
                  fontSize: '1.1rem',
                  lineHeight: '1.3rem',
                },

                '@media(max-width:800px)': {
                  fontSize: '1rem',
                  lineHeight: '1.2rem',
                },
              },
            },
            sx: {
              '& .MuiInput-underline:before': {
                borderBottomColor: '#fff',
              },
              '& .Mui-error.MuiInput-underline:before': {
                borderBottomColor: '#ff5252',
              },
              '& .MuiInput-underline:after': {
                color: '#fff',
              },
              '& .MuiInputLabel-root.Mui-error': {
                color: '#ff5252',
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
              },
              width: '100%',
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}

import { ReactNode } from 'react';
import {
  ListItemText,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from '@mui/material';
import { t } from 'i18next';
import { Character, User } from 'types';

type Props = {
  label: string;
  id: string;
  value?: string[];
  i18nKey?: string;
  placeholder?: string;
  list?: string[];
  disabled?: boolean;
  multiple?: boolean;
  onChange?: (SelectChangeEvent) => void;
  readonly?: boolean;
  numbered?: boolean;
  numberedValue?: number | number[];
  numberedList?: User[] | Character[];
  error?: boolean;
  errorMsg?: string;
  helperText?: string;
};

export function SelectField(props: Readonly<Props>): ReactNode {
  function isUser(item: User | Character): item is User {
    return (item as User).profile !== undefined;
  }
  function isNumberArray(
    value: number | number[] | undefined
  ): value is number[] {
    return (
      Array.isArray(value) && value.every((item) => typeof item === 'number')
    );
  }

  function isUserArray(list?: User[] | Character[]): list is User[] {
    return Array.isArray(list) && list.every((item) => isUser(item));
  }

  const convertToLocalString = (selected, i18nKey) => {
    // Handle non-numbered list cases
    if (!props.numbered) {
      const processItem = (item: string) =>
        props.i18nKey && item ? t(`${i18nKey}.${item}`) : item;

      return Array.isArray(selected)
        ? selected.filter(Boolean).map(processItem).join(', ')
        : processItem(selected);
    }

    // Handle numbered list cases
    const getPersonName = (person: User | Character) =>
      isUser(person) ? person.profile?.nickname : person.name;

    // Handle array of IDs
    if (isNumberArray(selected)) {
      return selected
        .map((id) => {
          const selectedPerson = props.numberedList.find((u) => u.id === id);
          return selectedPerson ? getPersonName(selectedPerson) : '';
        })
        .filter(Boolean)
        .join(', ');
    }

    // Handle single ID
    const selectedPerson = props.numberedList.find((u) => u.id === selected);
    return selectedPerson ? getPersonName(selectedPerson) : '';
  };

  function sortedList() {
    if (props.list) {
      const listCopy = [...props.list];
      if (props.i18nKey) {
        listCopy.sort((a, b) =>
          t(props.i18nKey + '.' + a) > t(props.i18nKey + '.' + b) ? 1 : -1
        );
      }
      listCopy?.sort((a, b) => a.localeCompare(b));
      return listCopy;
    }

    if (props.numberedList) {
      if (isUserArray(props.numberedList)) {
        const sortedList = props.numberedList.sort((a, b) =>
          a.profile?.nickname.localeCompare(b.profile?.nickname)
        );
        return sortedList;
      } else {
        const sortedList = props.numberedList.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        return sortedList;
      }
    }
  }

  return (
    <FormControl
      variant='standard'
      sx={{
        width: '100%',
        '& .MuiInputBase-root': {
          borderBottom: '1px solid #fff',
          height: 'inherit',
        },
        '& .MuiSelect-select': {
          color: '#fff',
          fontFamily: 'Neuton',
        },
        label: {
          fontSize: '1.1rem',
          fontFamily: 'Neuton',
          color: 'rgba(255, 255, 255, 0.7)',
          '& .MuiInputBase-input.Mui-disabled': {
            color: 'rgba(206, 205, 205, 0.7)',
            WebkitTextFillColor: 'rgba(206, 205, 205, 0.7)',
          },
          '@media(max-width:5000px)': {
            fontSize: '1.2rem',
            lineHeight: '1.5rem',
          },

          '@media(max-width:1440px)': {
            fontSize: '1.0rem',
            lineHeight: '1.3rem',
          },

          '@media(max-width:800px)': {
            fontSize: '0.8rem',
            lineHeight: '1rem',
          },
        },
      }}
      error={props.error}
    >
      <InputLabel id={props.id + '-selection-label'}>{props.label}</InputLabel>
      <Select
        inputProps={{
          readOnly: props.readonly,
        }}
        labelId={props.id + '-selection-label'}
        id={props.id + '-selection'}
        label={props.id}
        value={props.value || props.numberedValue || []}
        renderValue={(selected) =>
          convertToLocalString(selected, props.i18nKey)
        }
        fullWidth
        multiple={props.multiple}
        onChange={props.onChange}
        placeholder={props.placeholder}
        disabled={props.disabled}
        sx={{
          height: '60px',
          '& .MuiSvgIcon-root': {
            color: 'primary.main',
          },
        }}
        MenuProps={{
          slotProps: {
            paper: {
              style: {
                backgroundColor: '#1f1f1f',
              },
            },
          },
        }}
      >
        {sortedList()?.map((item) => (
          <MenuItem
            key={props.numbered ? item?.id : item}
            value={props.numbered ? item?.id : item}
            sx={{
              backgroundColor: '#1e1e1e',
              color: '#fff',
              fontFamily: 'Neuton',
              '&:hover': {
                backgroundColor: '#303030',
              },
            }}
          >
            {props.numbered ? (
              isUser(item) ? (
                <ListItemText primary={item?.profile?.nickname} />
              ) : (
                <ListItemText primary={item?.name} />
              )
            ) : (
              <>
                <Checkbox
                  sx={{
                    '& .MuiSvgIcon-root': {
                      color: 'primary.main',
                    },
                  }}
                  checked={props.value?.indexOf(item) > -1}
                />
                <ListItemText
                  primary={props.i18nKey ? t(props.i18nKey + '.' + item) : item}
                />
              </>
            )}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {props?.error ? props?.errorMsg : props?.helperText}
      </FormHelperText>
    </FormControl>
  );
}

import { ReactNode } from 'react';
import { Typography } from '@mui/material';

type Props = {
  title: string;
};
export function TabHeadline(props: Readonly<Props>): ReactNode {
  return (
    <Typography
      variant='h1'
      component='p'
      sx={{
        fontSize: '2rem',
        fontWeight: '400',
        textAlign: 'left',
        lineHeight: '50px',
        marginBottom: '20px',
        '@media(max-width:5000px)': {
          fontSize: '2.8rem',
          lineHeight: '3rem',
        },

        '@media(max-width:3000px)': {
          fontSize: '2.5rem',
          lineHeight: '2.8rem',
        },
      }}
    >
      {props.title}
    </Typography>
  );
}
